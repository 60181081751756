import React from "react";
import { SocialIcon } from 'react-social-icons';
import "./Footer.css";
import { NavLink } from "react-router-dom";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const Foot = () => (
  
  <footer>
  <div className="footerContainer">
      <div className="socialIcons">
          <i><SocialIcon  network="tiktok" url="" /></i>
          <i><SocialIcon network="facebook" url="" /></i>
          <i><SocialIcon network="instagram" url="" /></i>
          <i><SocialIcon network="snapchat" url="" /></i>
      </div>
      <div className="footerNav">
          <ul>
            <NavLink onClick={scrollToTop} className="footerNav" to="/">
            <li>Home</li>
            </NavLink>
            <NavLink onClick={scrollToTop} className="footerNav" to="/trgovina">
            <li>Trgovina</li>
            </NavLink>
            <NavLink onClick={scrollToTop} className="footerNav" to="/about">
            <li>O meni</li>
            </NavLink>
            <NavLink  onClick={scrollToTop} className="footerNav" to="/">
            <li>Kontaktiraj me</li>
            </NavLink>
          </ul>
      </div>
      <div className="footerNav">
          <ul>
              <table className="table text-center table-dark">
              <tbody className="fw-normal">
                <tr>
                  <td>Pon - Čet:</td>
                  <td>8:00 - 16:00</td>
                </tr>
                <tr>
                  <td>Pet - Sob:</td>
                  <td>8:00 - 12:00</td>
                </tr>
                <tr>
                  <td>Nedela:</td>
                  <td>FREJ!</td>
                </tr>
              </tbody>
            </table>
          </ul>
      </div>
  </div>


 
  
  <div className="footerBottom">
      © 2024 Copyright: 
      <a className="text-white designer" href="https://zganjar.wordpress.com/">
        Balin Majster d.o.o.
      </a>
    </div>
  </footer>
);

export default Foot;
