import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import "./stylekazalo.css";

function BasicExample() {
  return (
    <Navbar
      bg="dark"
      data-bs-theme="dark"
      expand="lg"
      className="bg-body-tertiary"
    >
      <Container>
        <Navbar.Brand className="navbar-brand" href="/">BOBEK s.p.</Navbar.Brand>
         <Navbar.Toggle aria-controls="basic-navbar-nav" />
           <Navbar.Collapse id="basic-navbar-nav">
          
              <NavLink className="kazalo" 
                  to="/"
              >
              <li className="active">Domov</li>
              </NavLink>
              
              <Nav className="me-auto">
                <NavLink
                  to="/trgovina"
                  className="kazalo"
                >
                  <li>Trgovina</li>
                </NavLink>
               
                <NavLink
                  to="/about"
                  className="kazalo">
                 <li>O meni</li>
                </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;
